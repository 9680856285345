import { Suspense } from 'react';
import styled, { css } from 'styled-components';

import {
  AdvisaLogo,
  SamblaLogo,
  OmalainaLogo,
  RahoituLogo,
  DigifinansLogo,
  RahalaitosLogo,
} from '@advisa/wallet/svg';
import AdvisaSamlaLan from '../../images/advisa-samla-lan.svg?react';
import SamblaSamlaLan from '../../images/sambla-samla-lan.svg?react';
import Spinner from '../components/atoms/Spinner';

const device = {
  mobile: '(min-width : 0rem) and (max-width : 32rem)',
  desktop: '(min-width : 114rem)',
  laptop: '(min-width: 62rem)',
};

const imageStyles = css`
  width: 495px;
  height: 400px;
  padding-right: 0.4em;

  @media ${device.mobile} {
    width: 110%;
    height: 305px;
  }

  @media ${device.desktop} {
    width: 600px;
    height: 595px;
  }
`;

const StyledAdvisaSamlaLan = styled(AdvisaSamlaLan)`
  ${imageStyles}
`;

const StyledSamblaSamlaLan = styled(SamblaSamlaLan)`
  ${imageStyles}
`;

const Advisa = <AdvisaLogo data-is-inverted="true" style={{ width: '180' }} />;
const Sambla = <SamblaLogo data-is-inverted="true" style={{ width: '180' }} />;
const Omalaina = <OmalainaLogo style={{ width: '200' }} />;
const Rahoitu = <RahoituLogo style={{ width: '200' }} />;
const Rahalaitos = <RahalaitosLogo data-is-inverted="true" style={{ width: '200' }} />;
const Digifinans = <DigifinansLogo data-is-inverted="true" style={{ width: '180' }} />;

const AdvisaQandA = (
  <Suspense fallback={<Spinner classes={['c-spinner--white@desktop']} />}>
    <StyledAdvisaSamlaLan />
  </Suspense>
);

const SamblaQandA = (
  <Suspense fallback={<Spinner classes={['c-spinner--white@desktop']} />}>
    <StyledSamblaSamlaLan />
  </Suspense>
);

export { Advisa, Sambla, Omalaina, AdvisaQandA, SamblaQandA, Rahoitu, Rahalaitos, Digifinans };
