export default [
  'Aktia',
  'Aurajoki',
  'Avida',
  'BigBank',
  'Bondora',
  'BRABank',
  'Credigo',
  'Alisa Pankki',
  'GFMoney',
  'Instabank',
  'IPF',
  'Kaarna',
  'Morrow Bank',
  'MyLener',
  'Nordax',
  'POP Pankki',
  'Re:member',
  'Resurs Bank',
  'Saldo',
  'Santander',
  'Svea',
  'Sweep',
  'TF Bank',
];
