import {
  NoAvidaFinansLogo,
  NoBankNorwegianLogo,
  NoEikaLogo,
  NoExpressBankLogo,
  NoFacitLogo,
  NoFerratumLogo,
  NoIkanoBankLogo,
  NoInstabankLogo,
  NoLeaBankLogo,
  NoMorrowBankLogo,
  NoNordaxLogo,
  NoNordeaLogo,
  NoNstartLogo,
  NoRememberLogo,
  NoResursLogo,
  NoSvealånetLogo,
  NoTfBankLogo,
  NoThornLogo,
  NoYaResursLogo,
} from '@advisa/wallet/svg';

const LOGOS = {
  avida: <NoAvidaFinansLogo />,
  norwegian: <NoBankNorwegianLogo />,
  eika: <NoEikaLogo />,
  express: <NoExpressBankLogo />,
  facit: <NoFacitLogo />,
  ferratum: <NoFerratumLogo />,
  'ikano-bank': <NoIkanoBankLogo />,
  ikano: <NoIkanoBankLogo />,
  instabank: <NoInstabankLogo />,
  leabank: <NoLeaBankLogo />,
  komplett: <NoMorrowBankLogo />,
  nordax: <NoNordaxLogo />,
  nordea: <NoNordeaLogo />,
  nstart: <NoNstartLogo />,
  remember: <NoRememberLogo />,
  resurs: <NoResursLogo />,
  svea: <NoSvealånetLogo />,
  tfbank: <NoTfBankLogo />,
  thorn: <NoThornLogo />,
  ya: <NoYaResursLogo />,
};

export default LOGOS;
