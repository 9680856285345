import { APPLICATION_OPTIMIZE_STATE } from '_js/constants/ApplicationOnHold';
import LOGOS from '../NO/CreditorLogos';
import creditors from '../SE/CreditorsName';

export default {
  phone: {
    number: '+4731421525',
    display: '31 - 42 15 25',
  },
  email: 'epost@digifinans.no',
  currency: {
    code: 'NOK',
    display: 'Nkr',
  },
  tracking: {
    googleTagManagerId: 'GTM-W95TZDQQ',
    hotjarId: 3374912,
  },
  tink: {},
  optimizeSteps: APPLICATION_OPTIMIZE_STATE,
  logos: LOGOS,
  creditors,
  website: {
    url: 'https://www.digifinans.no/',
    display: 'digifinans.no',
  },
  languageSwitchSelect: `url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="14" height="7"%3E%3Cpath fill="%23fff" fill-rule="evenodd" d="M5.732 6.56c.58.59 1.52.592 2.101 0l5.399-5.491C13.812.479 13.619 0 12.79 0H.776C-.05 0-.248.477.333 1.069L5.732 6.56z"/%3E%3C/svg%3E%0A');`,
};
