import { Helmet } from 'react-helmet';

import { getBrand, hasUniqueFavicon, ADVISA } from '.';

const constructSubfolderPath = (brand) => {
  if (!hasUniqueFavicon(brand)) {
    return ADVISA.toLowerCase();
  }

  return brand.toLowerCase();
};

const Favicon = () => {
  const brand = getBrand();

  const brandPublicSubfolder = constructSubfolderPath(brand);

  const iconsPath = `/icons/${brandPublicSubfolder}`;
  const manifestsPath = `/manifests/${brandPublicSubfolder}`;

  return (
    <Helmet>
      <link rel="icon" sizes="32x32" href={`${iconsPath}/favicon.ico`} />
      <link rel="icon" type="image/svg+xml" href={`${iconsPath}/icon.svg`} />
      <link rel="apple-touch-icon" href={`${iconsPath}/apple-touch-icon.png`} />
      <link rel="manifest" href={`${manifestsPath}/manifest.webmanifest`} />
    </Helmet>
  );
};

export default Favicon;
