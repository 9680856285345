import psl from 'psl';

import { isDevelopment } from '_js/utils/environment';
import advisaSeConstants from './config/ADVISA_SE/constants';
import advisaNoConstants from './config/ADVISA_NO/constants';
import advisaFiConstants from './config/ADVISA_FI/constants';
import samblaSeConstants from './config/SAMBLA_SE/constants';
import samblaNoConstants from './config/SAMBLA_NO/constants';
import digifinansNoConstants from './config/DIGIFINANS_NO/constants';
import samblaFiConstants from './config/SAMBLA_FI/constants';
import omalainaFiConstants from './config/OMALAINA_FI/constants';
import rahoituFiConstants from './config/RAHOITU_FI/constants';
import rahalaitosFiConstants from './config/RAHALAITOS_FI/constants';

import advisaTheme from './themes/advisa';
import samblaTheme from './themes/sambla';
import omalainaTheme from './themes/omalaina';
import rahoituTheme from './themes/rahoitu';
import rahalaitosTheme from './themes/rahalaitos';
import digifinansTheme from './themes/digifinans';
import {
  Advisa,
  Sambla,
  Omalaina,
  AdvisaQandA,
  SamblaQandA,
  Rahoitu,
  Rahalaitos,
  Digifinans,
} from './logos';

export const ADVISA = 'Advisa';
export const ADVISA_SE = 'Advisa_se';
export const ADVISA_FI = 'Advisa_fi';
export const ADVISA_NO = 'Advisa_no';
export const SAMBLA = 'Sambla';
export const SAMBLA_SE = 'Sambla_se';
export const SAMBLA_FI = 'Sambla_fi';
export const SAMBLA_NO = 'Sambla_no';
export const DIGIFINANS = 'Digifinans';
export const DIGIFINANS_NO = 'Digifinans_no';
export const OMALAINA = 'Omalaina';
export const OMALAINA_FI = 'Omalaina_fi';
export const RAHOITU = 'Rahoitu';
export const RAHOITU_FI = 'Rahoitu_fi';
export const RAHALAITOS = 'Rahalaitos';
export const RAHALAITOS_FI = 'Rahalaitos_fi';

export const SE = 'se';
export const FI = 'fi';
export const NO = 'no';
export const DK = 'dk';

export const ADVISA_LOW_CASE = 'advisa';
export const SAMBLA_LOW_CASE = 'sambla';
export const OMALAINA_LOW_CASE = 'omalaina';
export const RAHOITU_LOW_CASE = 'rahoitu';
export const RAHALAITOS_LOW_CASE = 'rahalaitos';
export const DIGIFINANS_LOW_CASE = 'digifinans';

export const BRANDS = [
  ADVISA_LOW_CASE,
  SAMBLA_LOW_CASE,
  OMALAINA_LOW_CASE,
  RAHOITU_LOW_CASE,
  RAHALAITOS_LOW_CASE,
  DIGIFINANS_LOW_CASE,
];

export const getBrand = () => {
  const parsedDomain = psl.parse(window.location.hostname);
  let domain = parsedDomain.sld;
  if (parsedDomain.subdomain) {
    const match = BRANDS.filter((brand) => parsedDomain.subdomain.includes(brand)).pop();
    if (match) {
      domain = match;
    }
  }

  if (isDevelopment() && localStorage.getItem('brand')) {
    domain = localStorage.getItem('brand');
  }

  switch (domain) {
    case SAMBLA_LOW_CASE:
      return SAMBLA;
    case ADVISA_LOW_CASE:
      return ADVISA;
    case OMALAINA_LOW_CASE:
      return OMALAINA;
    case RAHOITU_LOW_CASE:
      return RAHOITU;
    case RAHALAITOS_LOW_CASE:
      return RAHALAITOS;
    case DIGIFINANS_LOW_CASE:
      return DIGIFINANS;
    default:
      return ADVISA;
  }
};

export const getMarket = () => {
  const url = window.location.hostname;
  const parsed = psl.parse(url);
  return parsed.tld || 'se';
};

export const getLoginType = () => {
  switch (getMarket()) {
    case FI:
      return 'loginFinland';
    case NO:
      return 'loginNorway';
    default:
      return 'login';
  }
};

export const getBrandAndMarket = () => {
  const brand = getBrand();
  const market = getMarket();
  return `${brand}_${market}`;
};

export const getTheme = () => {
  if (typeof window === 'undefined') return {};

  switch (getBrand()) {
    case ADVISA:
      return advisaTheme;
    case SAMBLA:
      return samblaTheme;
    case OMALAINA:
      return omalainaTheme;
    case RAHOITU:
      return rahoituTheme;
    case RAHALAITOS:
      return rahalaitosTheme;
    case DIGIFINANS:
      return digifinansTheme;
    default:
      return advisaTheme;
  }
};

export const getBrandLogo = () => {
  if (typeof window === 'undefined') return {};
  switch (getBrand()) {
    case ADVISA:
      return Advisa;
    case SAMBLA:
      return Sambla;
    case OMALAINA:
      return Omalaina;
    case RAHOITU:
      return Rahoitu;
    case RAHALAITOS:
      return Rahalaitos;
    case DIGIFINANS:
      return Digifinans;
    default:
      return Advisa;
  }
};

export const getBrandQandA = () => {
  switch (getBrand()) {
    case ADVISA:
      return AdvisaQandA;
    case SAMBLA:
      return SamblaQandA;
    default:
      return AdvisaQandA;
  }
};

export const getBrandConstants = () => {
  const brand = getBrand();
  const market = getMarket();

  if (brand === ADVISA) {
    switch (market) {
      case SE:
        return advisaSeConstants;
      case FI:
        return advisaFiConstants;
      case NO:
        return advisaNoConstants;
      default:
        return advisaSeConstants;
    }
  } else if (brand === SAMBLA) {
    switch (market) {
      case SE:
        return samblaSeConstants;
      case NO:
        return samblaNoConstants;
      case FI:
        return samblaFiConstants;
      default:
        return samblaSeConstants;
    }
  } else if (brand === OMALAINA) {
    return omalainaFiConstants;
  } else if (brand === RAHOITU) {
    return rahoituFiConstants;
  } else if (brand === RAHALAITOS) {
    return rahalaitosFiConstants;
  } else if (brand === DIGIFINANS) {
    return digifinansNoConstants;
  }
  return advisaSeConstants;
};

export const hasUniqueFavicon = (brand) =>
  [SAMBLA, RAHOITU, OMALAINA, RAHALAITOS, DIGIFINANS].includes(brand);

export const getHomepageUrl = (currentLanguage) => {
  const { url } = getBrandConstants().website;
  const brand = getBrand();
  const brandsWithTranslationsOnHomepage = [ADVISA, SAMBLA];
  const translatedLanguagesOnHomepage = ['en', 'fi'];
  if (
    brandsWithTranslationsOnHomepage.includes(brand) &&
    translatedLanguagesOnHomepage.includes(currentLanguage)
  ) {
    return `${url}${currentLanguage}`;
  }
  return url;
};
