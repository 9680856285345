/* eslint-disable quote-props */
import {
  FiAktiaBankLogo,
  FiAlisaPankkiLogo,
  FiAurajokiLainaConsumerLogo,
  FiAurajokiLainaLimitaccountLogo,
  FiAvidaFinansLogo,
  FiBankNorwegianLogo,
  FiBigBankLogo,
  FiBondoraLogo,
  FiBroccLogo,
  FiCredigoLogo,
  FiEasyCreditLogo,
  FiFerratumLogo,
  FiGfMoneyLogo,
  FiHalinoLogo,
  FiInstaBankLogo,
  FiIpfCreditLogo,
  FiKaarnaLogo,
  FiLainaamoLogo,
  FiLeaBankLogo,
  FiMorrowBankLogo,
  FiMyLenderLogo,
  FiNordaxLogo,
  FiNorthmillBankLogo,
  FiPopPankkiLogo,
  FiRememberLogo,
  FiResursBankLogo,
  FiRisicumLogo,
  FiSaldoBankLogo,
  FiSantanderLogo,
  FiSveaLogo,
  FiSweepBankLogo,
  FiTfBankLogo,
} from '@advisa/wallet/svg';

// Sorted in alphabetic order
const LOGOS = {
  aktiabank: <FiAktiaBankLogo />,
  aurajoki_laina_consumer: <FiAurajokiLainaConsumerLogo />,
  aurajoki_laina_limitaccount: <FiAurajokiLainaLimitaccountLogo />,
  aurajoki_risicum_consumer: <FiRisicumLogo />,
  aurajoki_risicum_limitaccount: <FiRisicumLogo />,
  avida: <FiAvidaFinansLogo />,
  banknorwegian: <FiBankNorwegianLogo />,
  bigbank: <FiBigBankLogo />,
  bondora: <FiBondoraLogo />,
  brabank: <FiLeaBankLogo />,
  brocc: <FiBroccLogo />,
  credento: <FiTfBankLogo />,
  credigo: <FiCredigoLogo />,
  credit24: <FiIpfCreditLogo />,
  easycredit: <FiEasyCreditLogo />,
  'alisa-pankki': <FiAlisaPankkiLogo />,
  ferratum: <FiFerratumLogo />,
  finrefin: <FiTfBankLogo />,
  gfmoney: <FiGfMoneyLogo />,
  halens: <FiHalinoLogo />,
  instabank: <FiInstaBankLogo />,
  joustoluotto: <FiNorthmillBankLogo />,
  kaarna: <FiKaarnaLogo />,
  'morrow-private-loan': <FiMorrowBankLogo />,
  'morrow-credit-card': <FiMorrowBankLogo />,
  lainaamo: <FiLainaamoLogo />,
  leabank: <FiLeaBankLogo />,
  mylender: <FiMyLenderLogo />,
  nordax: <FiNordaxLogo />,
  pop: <FiPopPankkiLogo />,
  remember: <FiRememberLogo />,
  resursbank: <FiResursBankLogo />,
  'saldo-kulutusluotto': <FiSaldoBankLogo />,
  santander: <FiSantanderLogo />,
  svea: <FiSveaLogo />,
  'sweep-credit': <FiSweepBankLogo />,
  'tf-bank': <FiTfBankLogo />,
  'saldo-joustoluotto': <FiSaldoBankLogo />,
};

export default LOGOS;
